import React from "react"

class Doctolib extends React.Component {
  render() {
    return (
      <div>
        <a
          href="https://www.doctolib.fr/osteopathe/cusset/olivier-ortonne"
          rel="noreferrer"
          style={{
            display: "block",
            textAlign: "center",
            backgroundColor: "#0596DE",
            color: "#ffffff",
            fontSize: "14px",

            width: "300px",
            height: "100px",
            borderRadius: "5px",

            textDecoration: "none",
          }}
          target="_blank"
          data-reactroot=""
        >
          <span style={{ fontSize: "15px", lineHeight: "60px" }}>
            Prendre rendez-vous sur
          </span>
          <img
            style={{
              height: "25px",
              marginBottom: "3px",
              verticalAlign: "middle",
              width: "auto",
            }}
            src="https://www.doctolib.fr/external_button/doctolib-white-transparent.png"
            alt="Doctolib"
          />
          <div style={{ opacity: "0.4", fontSize: "15px" }}>7/7j - 24/24H</div>
        </a>
      </div>
    )
  }
}

export default Doctolib
