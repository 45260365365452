import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Doctolib from "../components/doctolib"

const TarifsPage = ({ data }) => (
  <Layout>
    <Seo title="Page two" />

    <Container>
      <div style={{ position: "relative" }}>
        <Img
          fixed={data.file.childImageSharp.fixed}
          style={{ maxHeight: 300, width: "100%", objectFit: "cover" }}
        />
        <div className="header-doctolib">{<Doctolib />}</div>
      </div>
      <h1>Hi from the tarifs page</h1>
      <p>Welcome to Profil</p>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
)

export default TarifsPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "mains_ostheopate.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 1280) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
